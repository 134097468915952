var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 bottom-overview-content",class:!_vm.menuCollapsed ? '' : 'detailed',staticStyle:{"right":"0","z-index":"10"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 popular-producers",class:{ 'popular-producers--promoter': _vm.isPromoter }},[_c('div',{staticClass:"row",staticStyle:{"margin-right":"0"}},[_c('div',{staticClass:"col-8 title-overlay",staticStyle:{"cursor":"pointer"},on:{"click":_vm.openProducerDetails}},[(_vm.isPromoter)?_c('span'):(
                  (_vm.customType === 'dj' || _vm.customType === 'dj-producer') &&
                    _vm.activeTab.name === 'DJ Overview'
                )?_c('span',[_vm._v(" DJ ")]):_c('span',[_vm._v("Producers")]),_vm._v(" INSIGHTS ")]),_c('div',{staticClass:"col-4",staticStyle:{"text-align":"right","position":"relative","padding-right":"0"}},[(!_vm.isPromoter)?_vm._l((_vm.producersMonthArray),function(month,index){return _c('span',{key:'producer-' + index,staticClass:"month-selector",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.showProducerDetails(month.id)}}},[_vm._v(" "+_vm._s(month.name)+" "),(month.selected)?_c('div',{staticClass:"month-selected"}):_vm._e()])}):_vm._e(),_c('svg',{staticClass:"show-all-arrow",class:!_vm.menuCollapsed ? '' : 'rotated',attrs:{"width":"12","height":"14","viewBox":"0 0 12 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":_vm.openProducerDetails}},[_c('path',{attrs:{"d":"M11 5.43896L6 1.43896L1 5.43896","stroke":"white"}})])],2)])]),(_vm.menuCollapsed)?_c('div',{staticClass:"popular__inner d-flex w-100"},[_c('div',{staticClass:"col-3 menu-insights"},[_c('div',{staticClass:"menu-insights__decor"}),_vm._l((_vm.sortedTabs),function(item){return _c('div',{key:item.id,staticClass:"menu-insights__item",class:[
                item.active ? 'active' : '',
                item.type ? '' : 'd-none'
              ],on:{"click":function($event){return _vm.onToggle(item.id)}}},[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])])})],2),_c('div',{staticClass:"col-9 p-0 m-0"},[(_vm.popularMenuDj[0].active)?_c('div',{staticClass:"popular-content",class:{ 'col-12': !_vm.isPromoter }},[(_vm.isPromoter)?[_c('b-table',{staticClass:"insights__table",attrs:{"fields":_vm.mostPlayedTracksFields,"items":_vm.mostPlayedTracksData}})]:(
                  (_vm.customType === 'dj' || _vm.customType === 'dj-producer') &&
                    _vm.djMostPopularTracks.length > 0 &&
                    _vm.activeTab.name === 'DJ Overview'
                )?_vm._l((_vm.djMostPopularTracks),function(track,index){return _c('div',{key:index,staticClass:"popular-row"},[_c('div',[_vm._v(_vm._s(index + 1))]),_c('div',[_c('div',{staticClass:"long-text",on:{"mouseenter":function($event){_vm.handleLongTextHover(
                          $event,
                          _vm.get(track, 'track.name', '')
                        )},"mouseleave":_vm.handleLongTextMouseLeave}},[_c('span',[_vm._v(_vm._s(_vm.get(track, "track.name", "")))])])]),_c('div',[_c('div',{staticClass:"long-text",on:{"mouseenter":function($event){_vm.handleLongTextHover(
                          $event,
                          _vm.get(track, 'track.performers', [])
                            .map(function (ref) {
                                          var name = ref.name;

                                          return name;
                        })
                            .join(', ')
                        )},"mouseleave":_vm.handleLongTextMouseLeave}},[_vm._v(" "+_vm._s(_vm.get(track, "track.performers", []) .map(function (ref) {
                                      var name = ref.name;

                                      return name;
                }) .join(", "))+" ")])])])}):(
                  (_vm.customType === 'producer' ||
                    _vm.customType === 'dj-producer') &&
                    _vm.producerMostPopularTracks.length > 0 &&
                    _vm.activeTab.name === 'Producer Overview'
                )?_vm._l((_vm.producerMostPopularTracks),function(track,index){return _c('div',{key:index,staticClass:"popular-row",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleTrackClick(track.track.id)}}},[_c('div',[_vm._v(_vm._s(index + 1))]),_c('div',[_c('div',{staticClass:"long-text",on:{"mouseenter":function($event){return _vm.handleLongTextHover($event, track.track.name)},"mouseleave":_vm.handleLongTextMouseLeave}},[_vm._v(" "+_vm._s(track.track.name)+" ")])]),_c('div',[_c('div',{staticClass:"long-text",on:{"mouseenter":function($event){_vm.handleLongTextHover(
                          $event,
                          track.track.performers
                            .map(function (ref) {
                                          var name = ref.name;

                                          return name;
                })
                            .join(', ')
                        )},"mouseleave":_vm.handleLongTextMouseLeave}},[_vm._v(" "+_vm._s(track.track.performers .map(function (ref) {
                                      var name = ref.name;

                                      return name;
                }) .join(", "))+" ")])])])}):(
                  (_vm.customType === 'dj' || _vm.customType === 'dj-producer') &&
                    ((!_vm.djMostPopularTracks.length &&
                      _vm.activeTab.name === 'DJ Overview') ||
                      (!_vm.producerMostPopularTracks.length &&
                        _vm.activeTab.name === 'Producer Overview'))
                )?[_c('div',{staticClass:"no-data"},[_vm._v("No data yet")])]:_vm._e()],2):(_vm.popularMenuDj[1].active || _vm.popularMenuDj[2].active)?_c('div',{staticClass:"popular-content",class:{ 'col-12': !_vm.isPromoter }},[(_vm.isPromoter)?[_c('b-table',{staticClass:"insights__table",attrs:{"fields":_vm.mostPlayedProducersFields,"items":_vm.mostPlayedProducersData}})]:_vm._e(),(
                  (_vm.customType === 'dj' || _vm.customType === 'dj-producer') &&
                    _vm.djMostPopularProducers.length > 0 &&
                    _vm.activeTab.name === 'DJ Overview'
                )?_vm._l((_vm.djMostPopularProducers),function(producer,i){return _c('div',{key:i,staticClass:"popular-row"},[_c('div',[_vm._v(_vm._s(i + 1))]),_c('div',[_vm._v(_vm._s(producer.performer.name))])])}):(
                  (_vm.customType === 'producer' ||
                    _vm.customType === 'dj-producer') &&
                    _vm.producerMostPopulerDjs.length > 0 &&
                    _vm.activeTab.name === 'Producer Overview'
                )?_vm._l((_vm.producerMostPopulerDjs),function(dj,index){return _c('div',{key:index,staticClass:"popular-row"},[_c('div',[_vm._v(_vm._s(index + 1))]),_c('div',[_vm._v(_vm._s(dj.user.primeArtistName))])])}):(
                  (_vm.customType === 'dj' || _vm.customType === 'dj-producer') &&
                    ((!_vm.djMostPopularProducers.length &&
                      _vm.activeTab.name === 'DJ Overview') ||
                      (!_vm.producerMostPopulerDjs.length &&
                        _vm.activeTab.name === 'Producer Overview'))
                )?[_c('div',{staticClass:"no-data"},[_vm._v("No data yet")])]:_vm._e()],2):(
                _vm.popularMenuDj[3].active &&
                  _vm.producerMostPopularVenues.length > 0
              )?_c('div',{staticClass:"col-12 popular-content"},[(
                  (_vm.customType === 'producer' ||
                    _vm.customType === 'dj-producer') &&
                    _vm.activeTab.name === 'Producer Overview'
                )?_vm._l((_vm.producerMostPopularVenues),function(el,index){return _c('div',{key:index,staticClass:"popular-row"},[_c('div',[_vm._v(_vm._s(index + 1))]),_c('div',[_vm._v(_vm._s(el.venueName))]),_c('div',[_vm._v(_vm._s(el.venueCity)+", "+_vm._s(el.venueCountry))])])}):_vm._e()],2):(_vm.popularMenuDj[4].active)?_c('div',{staticClass:"popular-content"},[_c('b-table',{staticClass:"insights__table",attrs:{"fields":_vm.playlistUploadsByDjFields,"items":_vm.playlistUploadsByDjData,"tbody-tr-class":"insights__dj-row"},on:{"row-clicked":_vm.handleDjRowClick}})],1):_c('div',[_vm._m(0)])])]):_vm._e()])])]),_c('div',{ref:"tooltip",staticClass:"text-tooltip"},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popular-content"},[_c('div',{staticClass:"no-data"},[_vm._v("No data yet")])])}]

export { render, staticRenderFns }